<template>
  <mega-modal
    ref="modal-2"
    class="modal-sm"
    :active="modal"
    @onClose="modal = false"
  >
    <mega-header :title="$t('new_business')" class="bg-lighter">
      <span class="text-muted">{{ $t("new_business_desc") }}</span>
    </mega-header>

    <div class="card-body">
      <mega-input
        :label="$t('new_business_name')"
        :valid="name.length > 0"
        v-model="name"
      />
    </div>

    <div class="card-body">
      <div class="navbar">
        <div class="navbar-group ml-auto">
          <mega-button class="btn-white mr-2" @click="modal = false">{{
            $t("close")
          }}</mega-button>
          <mega-button
            class="btn-primary mr-2"
            :disabled="!name"
            @click="submit"
            >{{ $t("new_business_add") }}</mega-button
          >
        </div>
      </div>
    </div>
  </mega-modal>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      modal: false
    };
  },
  methods: {
    submit() {
      let formData = new FormData();
      formData.append("name", this.name);

      this.$api.v2
        .post("/arp", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(() => {
          this.modal = false;
          window.location.reload();
        });
    },

    open() {
      this.name = "";
      this.modal = true;
    }
  }
};
</script>
