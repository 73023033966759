var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mega-modal",
    {
      ref: "modal-2",
      staticClass: "modal-sm",
      attrs: { active: _vm.modal },
      on: {
        onClose: function($event) {
          _vm.modal = false
        }
      }
    },
    [
      _c(
        "mega-header",
        { staticClass: "bg-lighter", attrs: { title: _vm.$t("new_business") } },
        [
          _c("span", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("new_business_desc")))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("mega-input", {
            attrs: {
              label: _vm.$t("new_business_name"),
              valid: _vm.name.length > 0
            },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "navbar" }, [
          _c(
            "div",
            { staticClass: "navbar-group ml-auto" },
            [
              _c(
                "mega-button",
                {
                  staticClass: "btn-white mr-2",
                  on: {
                    click: function($event) {
                      _vm.modal = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("close")))]
              ),
              _vm._v(" "),
              _c(
                "mega-button",
                {
                  staticClass: "btn-primary mr-2",
                  attrs: { disabled: !_vm.name },
                  on: { click: _vm.submit }
                },
                [_vm._v(_vm._s(_vm.$t("new_business_add")))]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }